export default {
  componentType: 'card',
  endpoint: '/document(/:guid)',
  objectName: 'audio',
  objectType: 'document',
  url: '/audios/:guid',
  getUrl: data => '/audios/' + data.id,
  title: 'audio',
  titleCreate: 'audio.create',
  getValue: data => data.name,
  helpLink: 'https://app.raan.garagemca.org/docs/docs/howto#audio',
  additionalTitleData: [
    {
      key: 'inv',
      label: 'inv'
    },
    {
      key: 'code',
      label: 'code'
    }
  ],
  showReviewedByEditor: true,
  showNeedsVerificationByEditor: true,
  tabs: [
    {
      title: 'tab.basis',
      type: 'basis',
      children: [
        {
          title: 'audio',
          icon: 'icons/documents.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'kind', endpointParams: {'filter[id][prefix]': 'document.audio'}, label: 'events.table.kind', placeholder: 'events.table.kind', style: {width: 'calc(35% - 20px)', marginRight: '20px'}},
            {name: 'nobr', fieldName: 'name', label: 'events.table.title', placeholder: 'events.table.title', style: {width: '65%'}}
          ],
          side: 'left'
        },
        {
          title: 'languages',
          icon: 'icons/flag.svg',
          components: [
            {name: 'MultiSelect', relationship: 'languages', kind: 'language', placeholder: 'audio-languages', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'videos.field.organisations',
          icon: 'icons/organisation-icon.svg',
          components: {name: 'Relation', pointer: '/relDocumentOrganisation', components: [
            {name: 'Select', relationship: 'organisation', endpointParams: {'search[fields]': 'name'}, allowClear: true, placeholder: 'videos.relDocumentOrganisation.placeholder', createNew: true, createObjectName: 'organisation'},
            {name: 'Select', relationship: 'role', allowClear: true, endpointParams: {'filter[type]': 'audio,organisation'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'duration',
          icon: 'icons/time.svg',
          components: [
            {name: 'input', fieldName: 'durationHms', disabled: true, style: {width: '50%', paddingRight: '20px'}}
          ],
          side: 'left'
        },
        {
          title: 'creation-date',
          icon: 'icons/calendar-icon.svg',
          components: [
            {name: 'FullDate', fieldName: 'dateFrom', style: {width: '100%'}}
          ],
          side: 'left'
        },
        {
          title: 'keywords',
          icon: 'icons/keywords-icon.svg',
          components: {name: 'keywords', pointer: '/keywords', placeholder: 'individuals.field.keywords.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'description',
          icon: 'icons/description-icon.svg',
          components: {name: 'text', fieldName: 'description', placeholder: 'description.placeholder', style: {width: '100%'}},
          side: 'left'
        },
        {
          title: 'document.limitations.title',
          icon: 'icons/flag.svg',
          components: [
            { name: 'Bool', fieldName: 'specialCollection', label: 'document.limitations.special-collection' }
          ],
          side: 'left'
        },
        {
          title: 'collection',
          icon: 'icons/documents.svg',
          required: true,
          components: [
            {name: 'Select', relationship: 'collection', kind: 'collection', label: 'collection', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'document.storage-details',
          icon: 'icons/storage.svg',
          components: [
            {name: 'input', fieldName: 'inv', label: 'inv', disabled: true, style: {width: '100%'}},
            {name: 'Select', relationship: 'storageLocation', kind: 'storage_location', allowClear: true, endpointParams: {'filter[group]': 'raan'}, label: 'storage-location', style: {width: '100%'}},
            {name: 'input', fieldName: 'code', label: 'code', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'accessType',
          icon: 'icons/shelf.svg',
          components: [
            {name: 'Select', relationship: 'accessType', kind: 'access_type', allowClear: true, label: 'accessType', style: {width: '100%'}}
          ],
          side: 'right'
        },
        {
          title: 'website-status',
          icon: 'icons/website-icon.svg',
          components: [
            {name: 'Switch', relationship: 'publishedOnWeb', kind: 'published_on_web', style: {float: 'none'}}
          ],
          side: 'right'
        },
        {
          title: 'source',
          icon: 'icons/organisation.svg',
          components: {name: 'AutoComplete', fieldName: 'source', endpoint: '/field_source', endpointParams: {'filter[type]': 'audio'}, placeholder: 'source.placeholder', style: {width: '100%'}},
          side: 'right'
        },
        {
          title: 'comment',
          icon: 'icons/comment.svg',
          components: {name: 'text', fieldName: 'notes', placeholder: 'comment.placeholder', style: {width: '100%'}},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.related-people',
      type: 'related-people',
      children: [
        {
          title: 'individuals',
          icon: 'icons/individual.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/person/type': 'individual'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'individual'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'individual'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'audio,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'groups',
          icon: 'icons/group.svg',
          components: {name: 'Relation', pointer: '/relPersonDocument', filter: {'/person/type': 'group'}, components: [
            {name: 'Select', relationship: 'person', endpointParams: {'filter[type]': 'group'}, placeholder: 'individuals.placeholder', createNew: true, createObjectName: 'group'},
            {name: 'Select', relationship: 'role', endpointParams: {'filter[type]': 'audio,person'}, placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.events',
      type: 'events',
      children: [
        {
          title: 'exhibitions',
          icon: 'icons/frame.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/event/kind/id': /^event.exhibition/}, components: [
            {name: 'Select', relationship: 'event', endpointParams: {'filter[kind][prefix]': 'event.exhibition', 'search[fields]': 'name'}, placeholder: 'event.placeholder', createNew: true, createObjectName: 'event'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'left'
        },
        {
          title: 'other-events',
          icon: 'icons/event-icon.svg',
          components: {name: 'Relation', pointer: '/relEventDocument', filter: {'/event/kind/id': /^(?!event.exhibition).*/}, components: [
            {name: 'Select', relationship: 'event', endpointParams: {'filter[kind][not_prefix]': 'event.exhibition', 'search[fields]': 'name'}, placeholder: 'event.placeholder', createNew: true, createObjectName: 'event'},
            {name: 'Select', relationship: 'role', fixed: 'role.default_event_document'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.archive',
      type: 'archive',
      children: [
        {
          title: 'documents',
          icon: 'icons/documents.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'archiveDocument'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'archive_document', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty', placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'ephemeris',
          icon: 'icons/efemerida.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'ephemeris'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'ephemeris', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty', placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        },
        {
          title: 'images',
          icon: 'icons/photo.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'image'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'image', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty', placeholder: 'role.placeholder'}
          ]},
          side: 'left'
        },
        {
          title: 'things',
          icon: 'icons/photo.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'thing'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'thing', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty', placeholder: 'role.placeholder'}
          ]},
          side: 'right'
        }
      ]
    },
    {
      title: 'tab.library',
      type: 'library',
      children: [
        {
          title: 'books',
          icon: 'icons/book.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'book'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'book', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        },
        {
          title: 'articles',
          icon: 'icons/article.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'article'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'article', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.media',
      type: 'media',
      children: [
        {
          title: 'audios',
          icon: 'icons/audio.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'audio'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'audio', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'right'
        },
        {
          title: 'videos',
          icon: 'icons/video.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'video'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'video', 'search[fields]': 'name'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        },
        {
          title: 'media-items',
          icon: 'icons/documents.svg',
          components: {name: 'Relation', pointer: '/relDocumentDocument', filter: {'/document/type': 'mediaItem'}, components: [
            {name: 'Select', relationship: 'document', endpointParams: {'filter[type]': 'media_item'}, placeholder: 'document.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.research',
      type: 'research',
      children: [
        {
          title: 'research',
          icon: 'icons/comment.svg',
          components: {name: 'Relation', pointer: '/relDocumentResearch', filter: {'/research/type': 'research'}, components: [
            {name: 'Select', relationship: 'research', placeholder: 'research.placeholder'},
            {name: 'Select', relationship: 'role', fixed: 'role.empty'}
          ]},
          side: 'left'
        }
      ]
    },
    {
      title: 'tab.files',
      type: 'files',
      children: [
        {
          title: 'audio.files',
          wrapper: false,
          icon: 'icons/media-archive.svg',
          components: [
            {name: 'Files', relationship: 'files'}
          ],
          side: 'both'
        }
      ]
    }
  ]
}
