import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Route from 'route-parser'
import Button from '../components/Button'
import Switch from '../components/Switch'
import { update } from '../redux/actions/apiActions'
import { injectIntl } from 'react-intl'
import Modal from 'antd/lib/modal'
import { editField } from '../redux/actions/editingCard'
import Editable from './Editable'
import moment from 'moment'

class CardEditorControls extends PureComponent {

  constructor() {
    super()
    this.state = {
      isOpenVerificationModal: false,
      verificationModalNotes: ''
    }
  }

  getDataEndpoint(additional = '') {
    const route = new Route(this.props.config.endpoint)
    const endpoint = route.reverse({ guid: this.props.data.id }) + additional

    return endpoint
  }

  verifyByEditor(status) {
    const { config, data, user } = this.props

    const endpoint = this.getDataEndpoint('/verify_by_editor')

    let modifier = {
      needs_verification_by_editor: status,
      notes: (data.notes ? data.notes + '\n\n' : '') +
        '=== ' + this.props.intl.formatMessage({ id: 'editorFeedback.comment-message' }) + ' ===\n' +
        this.state.verificationModalNotes +
        '\n= От ' + user.email + ', ' + moment().format('DD.MM.YYYY HH:mm')
    }

    this.props.dispatch(update(endpoint, modifier, (error, data, normalised) => {
      const objectPointer = '/' + config.objectName + '/' + this.props.data.id + '/attributes/'
      this.props.dispatch(editField(this.props.tab.id, objectPointer + 'needsVerificationByEditor', status))

      if (status) {
        this.props.dispatch(editField(this.props.tab.id, objectPointer + 'notes', modifier.notes))
      }

      this.setState({ verificationModalNotes: '' })
    }))
  }

  reviewByEditor(status) {
    const { config } = this.props

    const endpoint = this.getDataEndpoint('/review_by_editor')

    const modifier = {
      reviewed_by_editor: status
    }

    this.props.dispatch(update(endpoint, modifier, (error, data, normalised) => {
      const objectPointer = '/' + config.objectName + '/' + this.props.data.id + '/attributes/reviewedByEditor'
      this.props.dispatch(editField(this.props.tab.id, objectPointer, status))
    }))
  }

  render() {
    const { intl, config, data, meta, user } = this.props

    const reviewByEditorEndpoint = this.getDataEndpoint('/review_by_editor')
    const verifyByEditorEndpoint = this.getDataEndpoint('/verify_by_editor')
    const isUserEditor = user.user_role && user.user_role.id === 'editor'

    const modalFooterContent = [
      <Button
        key="cancel"
        type="inline"
        onClick={() => this.setState({
          isOpenVerificationModal: false
        })}
      >
        {intl.formatMessage({ id: 'cancel' })}
      </Button>,
      <Button key="create" onClick={() => {
        this.verifyByEditor(true)
        this.setState({ isOpenVerificationModal: false })
      }}>
        {intl.formatMessage({ id: 'feedback.submit' })}
      </Button>
    ]

    return (
      <>
        {(config.showReviewedByEditor && data) && (
          <div className="CardControls__switchLabel">
            <Switch
              checked={data.reviewedByEditor}
              onClick={value => this.reviewByEditor(value)}
              disabled={!isUserEditor}
              loading={
                meta[reviewByEditorEndpoint] &&
                meta[reviewByEditorEndpoint][''].loading
              }
            />
            <span className="CardControls__switchLabelText">
              {intl.formatMessage({ id: 'reviewedByEditor' })}
            </span>
          </div>
        )}

        {(config.showNeedsVerificationByEditor && data) && (
          <div className="CardControls__switchLabel">
            <Switch
              checked={data.needsVerificationByEditor}
              loading={
                meta[verifyByEditorEndpoint] &&
                meta[verifyByEditorEndpoint][''].loading
              }
              onClick={value => {
                if (value) {
                  this.setState({
                    isOpenVerificationModal: true,
                    verificationModalNotes: ''
                  })
                } else {
                  this.verifyByEditor(false)
                }
              }}
            />
            <span className="CardControls__switchLabelText">
              {intl.formatMessage({ id: 'needsVerificationByEditor' })}
            </span>
          </div>
        )}

        <Modal
          className="CreateCard__modal"
          visible={this.state.isOpenVerificationModal}
          title={intl.formatMessage({ id: 'feedback' })}
          onCancel={() => this.setState({ isOpenVerificationModal: false })}
          footer={modalFooterContent}
        >
          <div style={{ marginTop: '20px' }}>
            <div className="CommonField" style={{ width: '100%' }}>
              <div className="LabelWrapper">
                <Editable
                  type="text"
                  value={this.state.verificationModalNotes}
                  placeholder={'editorFeedback.title'}
                  onChange={value => this.setState({ verificationModalNotes: value })}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }

}

const mapStateToProps = function (state, props) {
  return {
    meta: state.data.meta,
    user: state.user
  }
}

export default connect(mapStateToProps)(injectIntl(CardEditorControls))